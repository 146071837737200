import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving with kids</h1>

        Moving is hard enough but, kids make the job even tougher. Top Moving Deals provides you some tips which can make moving into a simple, forgettable task for your children.
        <br/><br/>

        <b>Do not delay in informing your kids about moving. </b>
        We often assume that it does not matter to kids of any age if we are planning or about to make a move to a new location.  The experts say the exact opposite. Kids take time to digest the fact that they are going to move and they have to adapt to new conditions. The earlier you tell them, the quicker they will accept it.
        <br/><br/>

        <b>Talk to your kids about moving. Answer any questions they might have. </b>
        Keep all lines of communication open with your kids and make them feel an important member of the family. This will in making them comfortable with the decision of moving. Their questions can help understand their thoughts about moving like if they are excited or worried. Try to get them as involved and see how they feel.
        <br/><br/>

        <b>Be confident about your moving decision.</b>
        Your confident attitude towards moving will greatly influence the mind of your kids. Your confused behavior can make them confused too about the whole decision. Be positive and give them good convincing reasons for moving to a new location. This will help them think and feel the same, and it will make them a lot more excited about the change of scenery.
        <br/><br/>

        <b>Get your kids involved in the moving process.</b>
        Make your kids understand that they are important members of the family and that they should also get involved in the entire moving process. Let them help you plan, pack, choose a house, and etc. Get them ready for the move and seek ideas from them in settling into your new house. Make them feel that their contributions hold the utmost importance in the process.
        <br/><br/>

        <b>Monitor your kids’ activities after moving to your new home.</b>
        Moving can change a child’s moods and make them a little angry or confused. Give them some time and extra support to adjust with the new surroundings. Soon, they will start appreciating their new environment and make new friends in the area.
      </div>
    </div>
  );
}