import React, { Component } from 'react'; 
import { connect} from 'react-redux'; 
import { loadMoveForm } from '../actions/index'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-132433706-2'); 

function getQueryVariable(queryString, variable) {
  var query = queryString.substring(1);  
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
          return pair[1];
      }
  }
  
  return undefined;
}

class Header extends Component {

  constructor() {
    super();
    this.state = {
      toZip: '',
      fromZip: '',
      size: '2br'
    };
    
    this.handleClick = this.handleClick.bind(this);
    this.updateToZip = this.updateToZip.bind(this);
    this.updateFromZip = this.updateFromZip.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  handleClick() {
    var uri = '/new';
    const data = {
      "fromZip" : this.state.fromZip,
      "toZip" : this.state.toZip,
      "size" : this.state.size
    }

    var referrer = getQueryVariable(this.props.location.search, "ref");
    
      if (typeof referrer === "undefined"){
        var uri = '/new';
      }
      else 
      {
        var uri = '/new?ref=' + referrer;     
    }

    ReactGA.event({
      category: 'Conversion',
      action: 'Start Form'
    });  
    
    this.props.loadMoveForm(data)
    this.props.history.push(uri);
  }
  
  updateToZip(e) {
    this.setState( {
      toZip: e.target.value
    });
  }
  
  updateFromZip(e) {
    this.setState( {
      fromZip: e.target.value
    });
  }

  updateSize(e) {
    this.setState( {
      size: e.target.value
    });
  }

  render() {
    return(
      <div className="jumbotron">
        <div className="container text-white">

          <div className="row justify-content-lg-center">
            
            <div className="col-lg-6" >
          
              <h1 className="mb-4">Save on Your Move</h1>
              <p> We help thousands of people like you save more on their moves every year. Fill out our quick form to start for free. </p>
            
            </div>
          
          </div>

          <div className="row justify-content-center">
            <input type="number" className="form-control form-control-lg col-md-2 mb-2 ml-1 mr-1" placeholder="From Zip (ex. 12345)" value = {this.state.fromZip} 
                  onChange = {this.updateFromZip} aria-describedby="basic-addon1" />
            <input type="number" pattern="\d*" className="form-control form-control-lg col-md-2 mb-2 ml-1 mr-1" placeholder="To Zip (ex. 98765)" value = {this.state.toZip} 
                  onChange = {this.updateToZip} aria-describedby="basic-addon1" />
            <select value={this.state.size} onChange={this.updateSize} className="form-control form-control-lg input-lg col-md-2 mb-2 ml-1 mr-1">
              <option value="studio">Studio</option>
              <option value="1br">1 bedroom</option>
              <option value="2br">2 bedrooms</option>
              <option value="3br">3 bedrooms</option>
              <option value="4br">4+ bedrooms</option>
            </select>
            <button className="btn-lg btn-primary col-md-3 input-lg mb-2 ml-1 mr-1" type="button" onClick = {this.handleClick}>Get Started!</button>
          </div>

          <div className="row justify-content-center">
            <p>Over 10 years of expertise with thousands of moves a year</p>
          </div>

        </div>
      </div>
    )
  }

}

export default connect (null, { loadMoveForm })(Header)