import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Moving your houseplants to your new home</h1>

        Plants are the most essential living things to beautify our homes. At the same time, their growth and maintenance is highly dependent upon how we take care of them. Moving them can be very difficult due to their vulnerability to damage.
        <br/><br/>

        At the time of moving to a new location, it becomes important for you to move your houseplants or maybe garden plants, with utmost care. Top Moving Deals offers a few moving suggestions to its online readers to help successfully move your plants to your new home. Plants are living things and so are of perishable nature.
        <br/><br/>

        Plants require special handling and care to prevent from damage during travel. As living things, plants need air, water and controllable temperatures to survive. Insurance companies do not provide protection for plants from any loss or damage. Moving companies will not move them very far.
        <br/><br/>

        If you are moving to a different state, you need to check if the new state allows the entry of plants. Agricultural states normally impose stricter rules for allowing the entry of plants such as California and Florida. These states pay extra caution while bringing plants into their states due to diseases or bugs they may carry. You may contact your local Department of Agriculture to find out more on rules and regulations.
        <br/><br/>

        Many states command transported plants to their states to be grown indoors in sterilized pots; no outdoor soil should be use for that matter. Any plant inside outdoor soil container has to be transplanted again with indoor sterilized potting soil. It can be bought at local nursery shops or garden centers before moving across state borders.
        <br/><br/>
        
        Shift your plants from clay pots to unbreakable plastic containers to prevent them from breaking 2-3 weeks prior to your moving day. Clay pots can also be heavy to carry and move during transport. It is recommended you transplant your plants from clay pots to clean plastic containers and sterilized potting soil. This way you can also see through them for any pests or insecticides and take good care of them.

      </div>
    </div>
  );
}