import React from 'react'; 

function trackConv(google_conversion_id, google_conversion_label) {
  var image = new Image(1, 1); 
  image.src = "//www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?label=" + google_conversion_label + "&script=0";  
}

export default(props) => {
  return(
    <div id="thanksBanner" className = "bg-light text-center text-dark py-5">
      
      {trackConv('AW-1065110161', '98cGCP7Z59QBEJGV8fsD')}

      <div className = "container panel">

        <h1><i className="fa fa-check-circle-o text-success"></i></h1>
        <h1>Thank you!</h1>
        Your form has been successfully submitted. Our fully licensed and insured movers will contact you soon.

        </div>
    </div>
  );
}