import React  from 'react'; 

export default(props) => {
  return (
    <div id="footer">
      <ul className = "list-inline">
        <li className="list-inline-item text-muted"> Copyright <i className="fa fa-copyright" aria-hidden="true"></i> 2017 </li>
        <li className="list-inline-item text-muted divider mx-2"> | </li>
        <li className="list-inline-item"> <a href="#" className="text-light"> TopMovingDeals </a> </li>
        <li className="list-inline-item text-muted divider mx-2"> | </li>
        <li className="list-inline-item"> <a href="/privacy" className="text-light"> Privacy & Security </a> </li>
      </ul>
    </div>
  )
}



