import React, { Component } from 'react'; 
import { connect } from 'react-redux'
import MoveNewForm from './move_new_form';
import { createMoveRequest } from '../actions';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-132433706-2'); 

function getQueryVariable(queryString, variable) {
  var query = queryString.substring(1);  
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
          return pair[1];
      }
  }
  
  return undefined;
}

class MoveNew extends Component {
  
  state = {
    editting: false,
  }

  componentDidMount() {

    let fromZip = this.props.values.fromZip
    let toZip = this.props.values.toZip

    window.scrollTo(0, 0)


    if (!fromZip || fromZip.length < 5 || !toZip || toZip.length < 5){

      ReactGA.event({
        category: 'Conversion',
        action: 'Long Form'
      }); 

      this.setState({
        editting: true,
      })
    } else {

      ReactGA.event({
        category: 'Conversion',
        action: 'Short Form'
      }); 

      this.setState({
        editting: false,
      })
    }
    

    this.onEdit = this.onEdit.bind(this);
  }

  onSubmit = (values) => {

    var referrer = getQueryVariable(this.props.location.search, "ref");

    if (typeof referrer === "undefined"){
      values.referrer = "none";
    }
    else 
    {
      values.referrer = referrer;      
    }
    
    ReactGA.event({
      category: 'Conversion',
      action: 'Submit Form'
    });  
    
    createMoveRequest(values, () => {
      this.props.history.push('/thanks');
    });
    
  }

  onEdit = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'Conversion',
      action: 'Edit Form'
    }); 

    this.setState({
      editting: true
    })
  }

  render() {
    return (
      <MoveNewForm 
        onSubmit={this.onSubmit} 
        onEdit = {this.onEdit}
        editting={this.state.editting}/> 
    );
  }
}

MoveNew = connect(state => { 
  var values = {
    fromZip: state.zip.data ? state.zip.data.fromZip: "",
    toZip: state.zip.data ? state.zip.data.toZip: "",
  }
  return {
    values
  }
}, null )(MoveNew);

export default connect(null, { createMoveRequest }) (MoveNew);