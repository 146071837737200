import React from 'react'; 
import logo from '../images/logo.png';


export default(props) => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <a className="navbar-brand logo" href="/"> <img src= {logo} alt="Top Moving Deals logo"/> </a>
      
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
          
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
              <a className="nav-link text-white" href="/">Top Moving Deals</a>
            </li>
            <li className="nav-item">
              <span className="navbar-text" href="#">Over 50,000 Interstate Moves A Year</span>
          </li>
        </ul>

        {/*<ul className="navbar-nav nav">
          <li className="nav-item mr-3">
            <span className="navbar-text" href="#">1-800-914-7616 </span>
          </li>
        </ul>*/}
        <a className="btn btn-outline-light" href="/new">Find Movers</a>
      </div>
    </nav>
  )
}

 
