import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; 
import { createStore, applyMiddleware } from 'redux'; 
import { Router, Route, Switch } from 'react-router-dom'; 
import promise from 'redux-promise'; 
import MoveNew from './components/move_new';
import Header from './components/header'; 
import Jumbo from './components/jumbo';
import Save from './components/save_panel';
import HowItWorks from './components/how_it_works';
import Tips from './components/tips_panel'; 
import Footer from './components/footer';
import Privacy from './components/privacy';
import Packing from './components/packing';
import Storage from './components/storage';
import Moving from './components/moving';
import Thanks from './components/thanks';

import './index.css';

import TopTenMovingTips from './components/moving/top_ten_moving_tips';
import BestMovingOffers from './components/moving/best_moving_offers';
import MovingSeniorCitizens from './components/moving/moving_senior_citizens';
import MovingFragile from './components/moving/moving_fragile';
import ProfessionalMoving from './components/moving/professional_moving';
import MovingKids from './components/moving/moving_kids';
import MovingHouseplants from './components/moving/moving_houseplants';
import Loading from './components/moving/loading';
import CorporateRelocation from './components/moving/corporate_relocation';
import BusinessDataRelocation from './components/moving/business_data_relocation';
import StorageInfo from './components/moving/storage_info';
import TypesOfMoves from './components/moving/types_of_moves';
import Movingchecklist from './components/moving/moving_checklist';
import MovingStages from './components/moving/moving_stages';
import MovingTime from './components/moving/moving_time';
import GettingMovingEstimate from './components/moving/getting_moving_estimate';
import AvoidingRogueMovers from './components/moving/avoiding_rogue_movers';
import MovingTax from './components/moving/moving_tax';
import TechnicalEquipment from './components/moving/technical_equipment';
import PaymentClaims from './components/moving/payment_claims';
import LawsConsumer from './components/moving/laws_consumer';
import Insurance from './components/moving/insurance';
import LongDistance from './components/moving/long_distance';
import PianoMover from './components/moving/piano_mover';

import PackingHouseholdItems from './components/packing/packing_household_items';
import WhatToKeep from './components/packing/what_to_keep';
import StoringBelongings from './components/packing/storing_belongings';
import PackingMaterials from './components/packing/packing_materials';
import GeneralPackingTips from './components/packing/general_packing_tips';
import SettlingNewHome from './components/packing/settling_new_home';
import PackingKitchen from './components/packing/packing_kitchen_dining';
import PackingLiving from './components/packing/packing_living';
import PackingBedroom from './components/packing/packing_bedroom_bathroom';

import StorageConsiderations from './components/storage/storage_considerations';
import SelfStorage from './components/storage/self_storage';
import DecidingOnStorage from './components/storage/deciding_on_storage';
import StorageSecurity from './components/storage/security'; 

import reducers from './reducers';

// Start Google Analytics tracking
import ReactGA from 'react-ga';
import { createBrowserHistory as createHistory } from 'history'

ReactGA.initialize('UA-132433706-2');
ReactGA.pageview(window.location.pathname)

const history = createHistory()
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

const store = createStore(reducers, 
  applyMiddleware(promise),
);

ReactDOM.render(
  <Provider store={store}>
      <Router history={history}>
      <div>
        <Route path="/" component = { Header } />

        <Switch>
          <Route path="/privacy" component = { Privacy } />
          <Route exact path="/packing" component = { Packing } />
          <Route exact path="/moving" component = { Moving } />
          <Route exact path="/storage" component = { Storage} />
          <Route path="/new" component = { MoveNew } />
          <Route path="/thanks" component = { Thanks } />
          <Route exact path="/" component = { Jumbo } />  
        </Switch>
        
        <Route exact path="/" component = { Save } /> 
        <Route exact path="/" component = { HowItWorks } />
        <Route path="/new" component = { HowItWorks } />
        <Route path="/thanks" component = { HowItWorks} />


        <Route path="/moving/top_ten_moving_tips" component = { TopTenMovingTips } />
        <Route path="/moving/best_moving_offers" component = { BestMovingOffers } />
        <Route path="/moving/moving_senior_citizens" component = { MovingSeniorCitizens } />
        <Route path="/moving/moving_fragile" component = { MovingFragile } />
        <Route path="/moving/professional_moving" component = { ProfessionalMoving } />
        <Route path="/moving/moving_kids" component = { MovingKids} />
        <Route path="/moving/moving_houseplants" component = { MovingHouseplants } />
        <Route path="/moving/loading" component = { Loading } />
        <Route path="/moving/corporate_relocation" component = { CorporateRelocation } />
        <Route path="/moving/business_data_relocation" component = { BusinessDataRelocation } />
        <Route path="/moving/storage_info" component = { StorageInfo } />
        <Route path="/moving/types_of_moves" component = { TypesOfMoves } />
        <Route path="/moving/moving_checklist" component = { Movingchecklist } />
        <Route path="/moving/moving_stages" component = { MovingStages } />
        <Route path="/moving/moving_time" component = { MovingTime } />
        <Route path="/moving/getting_moving_estimate" component = { GettingMovingEstimate } />
        <Route path="/moving/avoiding_rogue_movers" component = { AvoidingRogueMovers } />
        <Route path="/moving/moving_tax" component = { MovingTax } />
        <Route path="/moving/technical_equipment" component = { TechnicalEquipment } />
        <Route path="/moving/payment_claims" component = { PaymentClaims } />
        <Route path="/moving/laws_consumer" component = { LawsConsumer } />
        <Route path="/moving/insurance" component = { Insurance } />
        <Route path="/moving/long_distance" component = { LongDistance } />
        <Route path="/moving/piano_mover" component = { PianoMover } /> 

        <Route path="/packing/packing_household_items" component = { PackingHouseholdItems } />
        <Route path="/packing/what_to_keep" component = { WhatToKeep } /> 
        <Route path="/packing/storing_belongings" component = { StoringBelongings } />
        <Route path="/packing/packing_materials" component = { PackingMaterials } />
        <Route path="/packing/general_packing_tips" component = { GeneralPackingTips } />
        <Route path="/packing/settling_new_home" component = { SettlingNewHome } />
        <Route path="/packing/packing_kitchen_dining" component = { PackingKitchen } />
        <Route path="/packing/packing_living" component = { PackingLiving } />
        <Route path="/packing/packing_bedroom_bathroom" component = { PackingBedroom } />  

        <Route path="/storage/storage_considerations" component = { StorageConsiderations } />
        <Route path="/storage/self_storage" component = { SelfStorage } /> 
        <Route path="/storage/deciding_on_storage" component = { DecidingOnStorage } />
        <Route path="/storage/security" component = { StorageSecurity } />

        <Route exact path="/" component = {Tips} />
        <Route path="/new" component = { Tips } />
        <Route path="/thanks" component = { Tips} />
        <Route path="/packing" component = { Tips } />
        <Route path="/moving" component = { Tips } />
        <Route path="/storage" component = { Tips } />
        <Route path="/" component = { Footer } />

      </div>
    </Router>
  </Provider>, document.getElementById('root'));