import React from 'react'; 

export default(props) => {
  return(
    <div className = "bg-white text-dark py-5">
      <div className = "container">

        <h1>Top 10 things to do DURING your move!</h1>

        Moving is more than just packing all of your things in cardboard and putting them in another location. Moving is about leaving a place and its memories and going to a new environment and being around new people. This process can become stressful and overwhelming. We at Top Moving Deals want to make moving as stress-free as possible.
        <br/><br/>
        Moving your home can become stressful if things are not planned well in advance. There are always some tasks that must be carried out at least a week in advance of the moving out date. Every individual has different priorities, and the moving is no exception to this rule. This makes it hard to make a generic Top 10 list, but a checklist of ten major things is just as handy.
        <ul>
          <li>It’s always a good idea to have some important phone numbers handy. These include people you may know in your new neighborhood, local authorities, and other major public services. This will help you if any unforeseeable circumstances occur.</li>
          <li>At least a week prior to moving, notify all major institutions you have to deal with about your address change. This would include tax offices, banks, and immigration offices.</li>
          <li>Make sure all utility bills such as phones, electricity, water, and etc are paid off before you move.</li>
          <li>Put valuables such as jewelry and important documentation such as passports, financial documents, and legal papers in bank deposits to guarantee their safety.</li>
          <li>On the day of the move, it is a good idea to organize for pet care services for pets as well as child care for the children.</li>
          <li>Inform the children’s’ old school about the move in advance. Make sure the children are admitted to the new school as soon as possible.</li>
          <li>Be certain that you have bought enough insurance to keep your important goods safe during the moving process.</li>
          <li>Any essential medication or prescriptions needed should be obtained well before moving for the health and well being of your family.</li>
          <li>On moving day, try to cook things that are simple and easy to cook. They should not require many utensils or cooking supplies. Sandwiches are ideal.</li>
          <li>Always have a list of the boxes and bags packed. Make sure the bags are clearly labeled. Preparing a list for one’s possessions that clearly defines all that is packed into each box or bag helps you know the importance of the boxes. This way, you would know what to open immediately and what you can delay opening.</li>
        </ul>
      </div>
    </div>
  );
}